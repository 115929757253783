import { ImageBackground, Modal, StyleSheet, TouchableOpacity, Linking, TextInput, Animated } from 'react-native';
import React, { useContext, useEffect, useRef, useState } from 'react';
import type { NativeStackScreenProps } from '@react-navigation/native-stack';
import { SOTFlowType } from '../types';
import { Text, View } from '../components/Themed';
import ButtonInverted from '../components/buttons/ButtonInverted';
import { SoTContext } from '../Global/SoTContext';
import { globalStyles } from '../Global/GlobalStyles';
import XClose from '../components/Illustrations/XClose';
import InviteFaceBook from '../components/Illustrations/InviteFaceBook';
import InviteTwitter from '../components/Illustrations/Twitter';
import { environment } from '../environments/environment';
import * as Clipboard from 'expo-clipboard';

type Props = NativeStackScreenProps<SOTFlowType>;
export default function Contest({ navigation }: Props) {
  console.log('Contest');
  const { user, setUser, userExtraData } = useContext(SoTContext);
  const [showShare, setShowShare] = useState(false);
  const url = `${environment.sphereOfTrustWebUrl}?sot=${userExtraData?.id || ''}`;
  const postText = 'DatingSphere lets close friends, exes, and family members show who someone is as a person.';
  const shareText = 'DatingSphere lets close friends, exes, and family members show who someone is as a person. #SoTContest #friendship';

  useEffect(() => {
    const handlePopState = () => {
      navigation.replace('SplashScreen');
    };

    // Listen for browser back button presses
    window.addEventListener('popstate', handlePopState);

    return () => {
      window.removeEventListener('popstate', handlePopState);
    };
  }, [navigation]);

  const opacity = useRef(new Animated.Value(1)).current;

  useEffect(() => {
    // Define the blinking animation
    const blink = Animated.loop(
      Animated.sequence([
        Animated.timing(opacity, {
          toValue: 0,   // Fade out to opacity 0
          duration: 1000, // Duration of fade-out
          useNativeDriver: true,
        }),
        Animated.timing(opacity, {
          toValue: 1,   // Fade out to opacity 0
          duration: 1000, // Duration of fade-out
          useNativeDriver: true,
        }),
        Animated.timing(opacity, {
          toValue: 1,   // Fade back to opacity 1
          duration: 5000, // Duration of fade-in
          useNativeDriver: true,
        }),
        Animated.timing(opacity, {
          toValue: 1,   // Fade back to opacity 1
          duration: 1000, // Duration of fade-in
          useNativeDriver: true,
        }),
      ])
    );

    // Start the blinking animation
    blink.start();

    // Cleanup animation on component unmount
    return () => blink.stop();
  }, [opacity]);

  return (
    <View nativeID="splashContainer" style={styles.container}>
      <ImageBackground
        style={styles.splashBG}
        source={require('../assets/images/bg.png')}
        resizeMode="cover"
      >
        <View style={{backgroundColor: 'transparent', position: 'absolute', top: globalStyles.container.height < 800 ? 50 : 100, alignItems: 'center'}}>
          <Text style={styles.title}>Thank you.</Text>
          <Text style={styles.title}>Intro Complete</Text>
        </View>
        <View style={styles.separatorClear} />
        <Text style={[styles.subTitle, {fontStyle: 'italic'}]}>BUT WAIT</Text>
        {/* <Text style={[styles.subTitle, {fontSize: globalStyles.container.height < 800 ? 30 : 36, fontStyle: 'italic'}]}>Enter our</Text> */}
        <Animated.Text style={[styles.subTitle, {opacity, color: '#FFF700', fontStyle: 'italic', fontSize: globalStyles.container.height < 800 ? 30 : 36}]}>Enter our #SoTContest</Animated.Text>
        <Animated.Text style={[styles.subTitle, {fontSize: globalStyles.container.height < 800 ? 30 : 36, opacity, color: '#FFF700', fontStyle: 'italic'}]}>to win a $50 gift card </Animated.Text>
       
        <TouchableOpacity style={styles.button} onPress={() => setShowShare(true)}>
            <Text style={styles.buttonText}>Share and Enter Contest</Text>
        </TouchableOpacity>
        <ButtonInverted
            style={{ position: 'absolute', bottom: 0 }}
            subStyle={{bottom: 30}}
            label="Download App"
            onPress={() => {
              Linking.openURL('https://datingsphereapp.onelink.me/xTAV/ptgdztby');
            }}
          />
      </ImageBackground>

      <Modal transparent animationType="fade" visible={showShare}>
        <View
          style={{
            bottom: 0,
            top: 0,
            position: 'absolute',
            left: 0,
            right: 0,
            flex: 1,
            backgroundColor: '#0007',
            justifyContent: 'center',
            alignItems: 'center',
            shadowColor: '#000',
            shadowOffset: {
              width: 0,
              height: 2,
            },
            shadowOpacity: 0.25,
            shadowRadius: 4,
          }}
        >
          <View style={styles.modalContainer}>
            <View
              style={{
                flexDirection: 'row',
                justifyContent: 'space-between',
                alignItems: 'center',
                paddingVertical: 10,
              }}
            >
              <Text style={styles.modalTitle}>Enter #SoTContest</Text>
              <TouchableOpacity
                style={{
                  width: 16,
                  height: 16,
                  backgroundColor: 'rgba(255,255,255,0)',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
                onPress={() => setShowShare(false)}
              >
                <View style={{}}>
                  <XClose />
                </View>
              </TouchableOpacity>
            </View>
            <View style={styles.modalBody}>
              <Text style={{ fontSize: 18 }}>
                DatingSphere will randomly select a monthly winner for a $50 gift card to promote
                friend bonding.
              </Text>
              <Text style={styles.bodyTextBold}>Share</Text>

              <View style={{}}>
              <TextInput
                editable={false}
                selectTextOnFocus={true}
                style={[styles.input, {paddingRight: 40}]}
                placeholder={shareText}
                value={shareText}
                numberOfLines={3}
                multiline
              />
              <TouchableOpacity
                style={{ position: 'absolute', top: 20, right: 12 }}
                onPress={() => {
                  Clipboard.setStringAsync(shareText);
                  alert('Link Copied :-)');
                }}
              >
                <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M27 4H11C10.7348 4 10.4804 4.10536 10.2929 4.29289C10.1054 4.48043 10 4.73478 10 5V10H5C4.73478 10 4.48043 10.1054 4.29289 10.2929C4.10536 10.4804 4 10.7348 4 11V27C4 27.2652 4.10536 27.5196 4.29289 27.7071C4.48043 27.8946 4.73478 28 5 28H21C21.2652 28 21.5196 27.8946 21.7071 27.7071C21.8946 27.5196 22 27.2652 22 27V22H27C27.2652 22 27.5196 21.8946 27.7071 21.7071C27.8946 21.5196 28 21.2652 28 21V5C28 4.73478 27.8946 4.48043 27.7071 4.29289C27.5196 4.10536 27.2652 4 27 4ZM26 20H22V11C22 10.7348 21.8946 10.4804 21.7071 10.2929C21.5196 10.1054 21.2652 10 21 10H12V6H26V20Z" fill="#093F76"/>
                </svg>
              </TouchableOpacity>
              </View>
              <View
                style={{
                  flexDirection: 'row',
                  justifyContent: 'center',
                  alignItems: 'center',
                  paddingTop: 30,
                  paddingBottom: 5,
                }}
              >
                <TouchableOpacity
                  style={styles.shareIconContainer}
                  onPress={() => {
                    const facebookShareUrl = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
                      url
                    )}&quote=${encodeURIComponent(postText)}`;
                    Linking.openURL(facebookShareUrl);
                  }}
                >
                  <InviteFaceBook />
                  <Text style={styles.shareIcontText}>Facebook</Text>
                </TouchableOpacity>
                <TouchableOpacity
                  style={styles.shareIconContainer}
                  onPress={() => {
                    Linking.openURL(
                      `http://twitter.com/intent/tweet?text=${postText}&hashtags=SoTContest,friendship&url=${url}`
                    );
                  }}
                >
                  <InviteTwitter />
                  <Text style={styles.shareIcontText}>Twitter</Text>
                </TouchableOpacity>
              </View>
            </View>
          </View>
        </View>
      </Modal>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: '#FFFFFF',
    width: `${globalStyles.container.width}px`,
    margin: 'auto',
  },
  facebook: {
    position: 'absolute',
    backgroundColor: '#DDDDDD',
    width: `${globalStyles.container.width}px`,
    height: 600,
    zIndex: 100000,
    borderRadius: 0,
    borderWidth: 8,
    borderColor: '#ffffff',
  },
  facebookHeader: {
    height: 100,
    backgroundColor: '#073768',
    padding: 20,
  },
  facebookBody: {
    height: 384,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#bbbbbb',
    overflow: 'hidden',
  },
  facebookFooter: {
    height: 100,
    backgroundColor: '#073768',
    padding: 28,
  },
  logo: {
    backgroundColor: 'transparent',
    top: globalStyles.headerOffset.distance,
  },
  splashBG: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    width: `${globalStyles.container.width}px`,
    margin: 'auto',
    paddingBottom: 50,
  },
  themeImage: {
    flex: 1,
    justifyContent: 'center',
    width: '100%',
    height: '100%',
  },
  title: {
    fontFamily: 'Ubuntu',
    fontSize: globalStyles.container.height < 800 ? 30 : 36,
    color: '#FFFFFF',
    // fontWeight: '600',
  },
  subTitle: {
    fontFamily: 'Ubuntu',
    fontSize: 46,
    color: '#FFFFFF',
  },
  separatorClear: {
    marginVertical: 15,
    borderColor: 'rgba(0,0,0,0)',
    backgroundColor: 'transparent',
    height: 1,
    width: '80%',
  },
  modalContainer: {
    width: globalStyles.container.width - 40,
    paddingHorizontal: 15,
    paddingVertical: 15,
    borderRadius: 10,
  },
  modalTitle: {
    fontWeight: '400',
    fontSize: 22,
  },
  modalBody: {
    borderTopWidth: 1,
    borderTopColor: '#DDDDDD',
    paddingTop: 20,
  },
  shareIconContainer: {
    justifyContent: 'center',
    alignItems: 'center',
    width: 80,
  },
  shareIcontText: {
    fontSize: 13,
  },
  input: {
    // height: 50,
    // width: '100%',
    flex: 1,
    borderColor: '#ADB5BD',
    borderWidth: 2,
    borderRadius: 8,
    padding: 10,
    // fontSize: 16,
    color: '#000',
  },
  bodyTextBold: {
    color: '#191919',
    marginTop: 30,
    fontSize: 18,
    fontFamily: 'Jost Regular',
    fontWeight: 'bold',
  },
  button: {
    width: 293,
    height: globalStyles.container.height < 800 ? 50 : 58,
    backgroundColor: '#FFFFFF',
    borderRadius: 80,
    marginTop: 30,
  },
  buttonText: {
    color: '#093F76',
    fontSize: 24,
    fontFamily: 'Jost Regular',
    marginTop: 'auto',
    marginBottom: 'auto',
    textAlign: 'center',
  },
});
