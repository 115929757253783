import { ScrollView, StyleSheet, TextInput, TouchableOpacity, Alert, Modal } from 'react-native';
import React, { useContext, useState, useRef, useEffect } from 'react';
import type { NativeStackScreenProps } from '@react-navigation/native-stack';
import { SOTFlowType } from '../types';
import { Text, View } from '../components/Themed';
import ButtonStandard from '../components/buttons/ButtonStandard';
import VerifyIcon from '../components/Illustrations/VerifyIcon';
import NavHeader from '../components/LayoutSections/NavHeader';
import { SoTContext } from '../Global/SoTContext';
import { globalStyles } from '../Global/GlobalStyles';
import { FirebaseRecaptchaVerifierModal } from 'expo-firebase-recaptcha';
import firebase from '../Global/firebase';

type Props = NativeStackScreenProps<SOTFlowType>;
export default function VerificationCode({ navigation }: Props) {
  console.log('VerificationCode');

  // const [verifyNumber, setVerifyNumber] = useState('4782749385927');
  const [readyMoveNext, setReadyMoveNext] = useState(false);
  const {
    mobile,
    verificationId,
    setFirstName,
    setYearsKnown,
    setRelationship,
    setAgeCheck,
    setBodyTypeCheck,
    setHasKidsCheck,
    setHeightCheck,
    setEducationCheck,
    setJobTitleCheck,
    setSmokesTobaccoCheck,
    setUserExtraData,
    setVerificationId,
    api,
    traitsPositive,
    traitsNeutral,
    setTraitsPositive,
    setTraitsNeutral,
    setTraitsDominant,
    setPersonality,
    setCompleteTheSentenceInsights,
    setOnceSentenceInsights,
    setPersonalStoryInsights,
    setImageData,
  } = useContext(SoTContext);
  const [modalVisible, setModalVisible] = useState(false);
  const [error, setError] = useState('');
  const [inputValues, setInputValues] = useState<string[]>(Array(6).fill(''));
  const recaptchaVerifier = useRef(null);
  const userId = window.localStorage.getItem('sot_user_id');
  // const userId = '3de35c0d-a13b-4b5d-a91b-b66fa4dce3e4';
  const inputRefs = Array(6)
    .fill(0)
    .map(() => useRef(null));

  useEffect(() => {
    const firstInputRef = inputRefs[0];
    firstInputRef.current?.focus();
  }, []);

  useEffect(() => {
    setError('');
    if (modalVisible) {
      const phoneProvider = new firebase.auth.PhoneAuthProvider();
      phoneProvider
        .verifyPhoneNumber(properPhone(mobile), recaptchaVerifier.current!)
        .then((id: any) => {
          console.log('sendVerification*1', id);
          setVerificationId(id);
          navigation.navigate('VerificationCode');
        })
        .catch(err => {
          console.error('Error verifying phone number:', err);
          setError('Error verifying phone number: ' + err.toString());
        })
        .finally(() => {
          setModalVisible(false);
        });
    }
  }, [modalVisible]);

  /* firebase phone number verification code */
  // Function to be called when requesting for a verification code
  const sendVerification = () => {
    setModalVisible(true);
  };

  function properPhone(phone: any) {
    return '+1' + phone.replace(/\D/g, '');
  }

  const requestedAgain = () => {
    console.log('Requesting again');
    sendVerification();
    setInputValues(Array(6).fill(''));
    for (const ref of inputRefs) {
      if (ref.current) {
        ref.current.value = '';
      }
    }
  };

  // Used to move the focus to the next input box
  const focusNextInput = (index: number) => {
    console.log('focusNextInput', index);
    if (index < inputRefs.length - 1) {
      const nextInputRef = inputRefs[index + 1];
      console.log('nextInputRef', nextInputRef);
      nextInputRef.current?.focus();
    }
    if (inputRefs.filter(ir => ir?.current?.value?.length).length === 6) {
      console.log('setNextReadyMoveNext');
      setReadyMoveNext(true);
    }
  };

  const isNumber = (value: any) => {
    return !isNaN(parseFloat(value)) && isFinite(value);
  };

  // Used to handle text input
  const handleTextChange = (text: any, index: number) => {
    // Update the text value in the current input box
    const inputBox: any = inputRefs[index].current;
    if (isNumber(text)) {
      inputBox.setNativeProps({ text });
      // Move the focus to the next input box
      focusNextInput(index);

      const values = [...inputValues];
      values[index] = text;
      setInputValues(values);
    }
  };

  const nextAction = async (result: any) => {
    if (userId && result.user?.uid) {
      api
        .getStorageDetails(userId, result.user.uid)
        .then(user => {
          const {
            firstName,
            relationship,
            yearsKnown,
            valid,
            positiveTraits,
            neutralTraits,
            dominantTrait,
            personality,
            completeTheSentence,
            oneSentence,
            story,
            photos,
          } = user;
          
          const parsedPostiveTraits = traitsPositive?.map((item) => {
            if (positiveTraits?.includes(item.label)) {
              return {...item, selected: true};
            } else {
              return item;
            }
          });
          const parsedNeutralTraits = traitsNeutral?.map((item) => {
            if (neutralTraits?.includes(item.label)) {
              return {...item, selected: true};
            } else {
              return item;
            }
          });

          const { age, body, smoke, hasKids, height, occupation, education } = valid;
          setUserExtraData(user);

          if (user.lastPage === 'Describe') {
            setFirstName(firstName);
            setRelationship(relationship);
            setYearsKnown(yearsKnown.toString() || '1');
            setAgeCheck(age.toString());
            setHasKidsCheck(hasKids.toString());
            setHeightCheck(height.toString());
            setBodyTypeCheck(body.toString());
            setEducationCheck(education.toString());
            setJobTitleCheck(occupation.toString());
            setSmokesTobaccoCheck(smoke.toString());
            navigation.navigate('Describe');
          } else if (user.lastPage === 'ShareInsight') {
            setFirstName(firstName);
            setRelationship(relationship);
            setYearsKnown(yearsKnown?.toString() || '1');
            setAgeCheck(age?.toString());
            setHasKidsCheck(hasKids.toString());
            setHeightCheck(height.toString());
            setBodyTypeCheck(body.toString());
            setEducationCheck(education.toString());
            setJobTitleCheck(occupation.toString());
            setSmokesTobaccoCheck(smoke.toString());
            setPersonality(personality);
            setTraitsPositive(parsedPostiveTraits);
            setTraitsNeutral(parsedNeutralTraits);
            setTraitsDominant(dominantTrait);
            navigation.navigate('ShareInsight');
          } else if (user.lastPage === 'ShareMedia') {
            setFirstName(firstName);
            setRelationship(relationship);
            setYearsKnown(yearsKnown?.toString() || '1');
            setAgeCheck(age?.toString());
            setHasKidsCheck(hasKids.toString());
            setHeightCheck(height.toString());
            setBodyTypeCheck(body.toString());
            setEducationCheck(education.toString());
            setJobTitleCheck(occupation.toString());
            setSmokesTobaccoCheck(smoke.toString());
            setPersonality(personality);
            setTraitsPositive(parsedPostiveTraits);
            setTraitsNeutral(parsedNeutralTraits);
            setTraitsDominant(dominantTrait);
            setCompleteTheSentenceInsights(completeTheSentence);
            setOnceSentenceInsights(oneSentence);
            setPersonalStoryInsights(story);
            navigation.navigate('ShareMedia');
          } else if (user.lastPage === 'Review') {
            setFirstName(firstName);
            setRelationship(relationship);
            setYearsKnown(yearsKnown?.toString() || '1');
            setAgeCheck(age?.toString());
            setHasKidsCheck(hasKids.toString());
            setHeightCheck(height.toString());
            setBodyTypeCheck(body.toString());
            setEducationCheck(education.toString());
            setJobTitleCheck(occupation.toString());
            setSmokesTobaccoCheck(smoke.toString());
            setPersonality(personality);
            setTraitsPositive(parsedPostiveTraits);
            setTraitsNeutral(parsedNeutralTraits);
            setTraitsDominant(dominantTrait);
            setCompleteTheSentenceInsights(completeTheSentence);
            setOnceSentenceInsights(oneSentence);
            setPersonalStoryInsights(story);
            setImageData(photos);
            navigation.navigate('Review');
          } else {
            navigation.navigate('LetsBegin');
          }
        })
        .catch(error => {
          console.log('error : ', error);
        });
    }
  };

  // Function to be called when confirming the verification code that we received
  // from Firebase via SMS
  const confirmCode = (code: any) => {
    // console.log('confirmCode');
    const credential = firebase.auth.PhoneAuthProvider.credential(verificationId, code);
    firebase
      .auth()
      .signInWithCredential(credential)
      .then(async result => {
        console.log('confirmCode*1', result);

        // CODE HAS BEEN VERIFIED
        nextAction(result);
        // navigation.navigate('LetsBegin');
      })
      .catch((error: any) => {
        console.error('confirmCode:error', error);
        // Alert.alert(
        //   'OTP Code Incorrect',
        //   `The 6 digit code you entered was invalid, please select "Request Again" to try again: ${error}`
        // );
        setError(
          `The 6 digit code you entered was invalid, please select "Request Again" to try again.`
        );
        // setReadyMoveNext(false);
      });
  };

  function submit() {
    console.log('inputValues', inputValues, inputValues.join(''));
    confirmCode(inputValues.join(''));
  }

  return (
    <View style={styles.container}>
      <Modal visible={modalVisible} animationType="slide">
        <FirebaseRecaptchaVerifierModal
          ref={recaptchaVerifier}
          firebaseConfig={firebase.app().options}
        />
      </Modal>
      <NavHeader
        style={{ marginBottom: 20 }}
        backButtonNavigation="Verify"
        props={navigation}
        steps={[]}
        width={globalStyles.container.width}
        height="112px"
      ></NavHeader>
      <ScrollView style={{ width: '100%', flex: 1 }}>
        <View style={styles.headerImageArea}>
          <VerifyIcon
            width={globalStyles.container.height < 800 ? 460 : 600}
            height={globalStyles.container.height < 800 ? 460 : 600}
          />
        </View>
        <View style={styles.miniSection}>
          <View
            style={{
              width: '100%',
              paddingHorizontal: 20,
              alignItems: 'flex-start',
            }}
          >
            <Text style={styles.title}>Enter your verification code</Text>
            <View style={{ flexDirection: 'row' }}>
              <Text style={styles.subTitle}>Enter the code sent to </Text>
              <Text style={styles.verifyNumber}>{mobile}.</Text>
            </View>
          </View>
          <View style={styles.separator} lightColor="#eee" darkColor="rgba(255,255,255,0.1)" />
          <View style={{ flexDirection: 'row', marginTop: 10 }}>
            {inputRefs.map((inputRef, index) => (
              <TextInput
                key={index}
                ref={inputRef}
                style={[styles.vCodeInput, { marginLeft: 0 === index ? 0 : 5 }]}
                maxLength={1}
                keyboardType="number-pad"
                onChangeText={text => handleTextChange(text, index)}
              />
            ))}
          </View>
          {error && (
            <View style={styles.error}>
              <Text
                style={{
                  fontFamily: 'Jost Regular',
                  fontSize: 16,
                  color: 'red',
                }}
              >
                {error}
              </Text>
            </View>
          )}
          <View style={styles.requestAgain}>
            <Text
              style={{
                fontFamily: 'Jost Regular',
                fontSize: 16,
                color: '#ADB5BD',
              }}
            >
              Didn't receive a code?{' '}
            </Text>
            <TouchableOpacity onPress={requestedAgain}>
              <Text
                style={{
                  fontFamily: 'Jost Regular',
                  fontSize: 16,
                  color: '#093f76',
                  textDecorationLine: 'underline',
                }}
              >
                Request again
              </Text>
            </TouchableOpacity>
          </View>
        </View>
      </ScrollView>
      <View
        style={{
          width: '100%',
          paddingBottom: globalStyles.container.height < 800 ? 10 : 20,
          paddingHorizontal: 20,
        }}
      >
        <ButtonStandard disabled={!readyMoveNext} label="Submit" onPress={() => submit()} />
      </View>
    </View>
  );
}
// background: rgb(20,76,135);
// background: linear-gradient(180deg, rgba(20,76,135,1) 0%, rgba(9,63,118,1) 49%, rgba(4,54,103,1) 98%);

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    backgroundColor: '#FFFFFF',
    width: `${globalStyles.container.width}px`,
    margin: 'auto',
    background: 'FFFFFF',
  },
  headerImageArea: {
    paddingVertical: globalStyles.container.height < 800 ? 0 : 20,
    // flex: 1,
    alignItems: 'center',
    // width: '100%',
    height: globalStyles.container.height < 800 ? 200 : 270,
  },
  title: {
    fontFamily: 'Ubuntu',
    fontWeight: '500',
    fontSize: globalStyles.container.height < 800 ? 22 : 26,
    color: '#212529',
    marginBottom: 8,
  },
  subTitle: {
    fontFamily: 'Jost Regular',
    fontSize: globalStyles.container.height < 800 ? 16 : 18,
    color: '#6C7570',
  },
  separator: {
    marginVertical: 10,
    margin: 'auto',
    height: 1,
    backgroundColor: '#DEE2E6',
    width: '90%',
  },
  miniSection: {
    flex: 2,
    paddingTop: 10,
    width: '100%',
    alignItems: 'center',
  },
  verifyNumber: {
    fontFamily: 'Jost Regular',
    fontSize: 18,
    color: '#6C7570',
    fontWeight: '600',
  },
  vCodeInput: {
    fontFamily: 'Jost Regular',
    fontSize: 18,
    color: '#212529',
    width: 50,
    height: 45,
    borderColor: '#ADB5BD',
    borderRadius: 2,
    borderWidth: 1,
    textAlign: 'center',
    marginLeft: 5,
  },
  entryHint: {
    color: '#6C7570',
    fontFamily: 'Jost Regular',
    fontSize: 14,
    marginTop: 15,
  },
  requestAgain: {
    flexDirection: 'row',
    width: '100%',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: 20,
  },
  error: {
    flexDirection: 'row',
    width: '100%',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: 20,
    paddingLeft: 25,
    paddingRight: 25,
  },
});
