import { Dimensions, Pressable, StyleSheet, Text } from 'react-native';
import React, { useEffect, useState } from 'react';
import { View } from '../Themed';
import { globalStyles } from '../../Global/GlobalStyles';

export interface BackNavigationProps {
  width: string | number;
  height: string;
  label?: string;
  steps?: any;
  style?: any;
  backButtonNavigation?: string;
  props?: any;
}

export default function NavHeader(backNavigationProps: BackNavigationProps) {
  const [steps, setSteps] = useState(backNavigationProps.steps || [1, 0, 0, 0, 0, 0, 0]);
  let backButtonNavigation = backNavigationProps.backButtonNavigation;

  useEffect(() => {
    setSteps(backNavigationProps.steps || [1, 1, 0, 0, 0, 0, 0]);
  });

  return (
    <View style={[styles.container, backNavigationProps.style]}>
      <View style={styles.navHeaderArea}>
        <View style={styles.headerContainer}>
          {backButtonNavigation && (
            <Pressable
              style={styles.backButton}
              onPress={() => backNavigationProps.props.navigate(backButtonNavigation)}
            >
              <svg
                width="14"
                height="24"
                viewBox="0 0 14 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M12 22L2 12L12 2"
                  stroke="#093F76"
                  strokeWidth="3"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </Pressable>
          )}
          <Text style={styles.backButtonText}>Sphere of Trust</Text>
        </View>
        {steps?.length > 0 && (
          <View
            style={{
              width: globalStyles.container.width - 40,
              flexDirection: 'row',
              position: 'absolute',
              top: globalStyles.container.height < 800 ? 65 : 100,
              zIndex: 100,
              alignItems: 'stretch',
              alignContent: 'center',
              justifyContent: 'space-between',
            }}
          >
            {steps.map((s, i) => (
              <View style={s === 0 ? styles.stepOn : styles.stepOff} key={i}></View>
            ))}
          </View>
        )}
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    shadowColor: '#000',
    shadowOffset: { width: 0, height: 0.3 },
    shadowOpacity: 0.4,
    shadowRadius: 16,
    elevation: 5,
    marginBottom: globalStyles.container.height < 800 ? 40 : 55,
  },
  navHeaderArea: {
    marginBottom: globalStyles.container.height < 800 ? 10 : 20,
    paddingHorizontal: 20,
    paddingTop: globalStyles.container.height < 800 ? 10 : 20,
    width: globalStyles.container.width,
  },
  headerContainer: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  navHeaderAreaText: {
    fontFamily: 'Ubuntu',
    fontWeight: '600',
    fontSize: 26,
    color: '#093F76',
    position: 'absolute',
    width: 300,
    top: 61,
    left: 21,
  },
  stepOff: {
    height: 8,
    width: '12%',
    backgroundColor: '#093F76',
    borderRadius: 4,
    marginRight: 5,
  },
  stepOn: {
    height: 8,
    width: '12%',
    backgroundColor: '#D9E8F6',
    borderRadius: 4,
    marginRight: 5,
  },
  backButton: {
    zIndex: 100,
    backgroundColor: 'white',
    elevation: 5,
    paddingRight: 16,
  },
  backButtonText: {
    fontSize: globalStyles.container.height < 800 ? 22 : 26,
    fontFamily: 'Ubuntu Medium',
    fontWeight: '500',
    color: '#093F76',
  },
});
