import { ScrollView, StyleSheet, TextInput } from 'react-native';
import React, { useContext } from 'react';
import type { NativeStackScreenProps } from '@react-navigation/native-stack';
import { SOTFlowType } from '../types';
import { Text, View } from '../components/Themed';
import ButtonStandard from '../components/buttons/ButtonStandard';
import NavHeader from '../components/LayoutSections/NavHeader';
import { SoTContext } from '../Global/SoTContext';
import { globalStyles } from '../Global/GlobalStyles';
import ExpandingTextInput from '../components/ExpandingTextInput';

type Props = NativeStackScreenProps<SOTFlowType>;
export default function ShareInsightScreen({ navigation }: Props) {
  console.log('ShareInsightScreen');

  const {
    user,
    oneSentenceInsights,
    setOnceSentenceInsights,
    completeTheSentenceInsights,
    setCompleteTheSentenceInsights,
    personalStoryInsights,
    setPersonalStoryInsights,
    api,
    userExtraData,
    setUserExtraData,
  } = useContext(SoTContext);

  const readyMoveNext = (fields: Array<string>) => {
    return fields?.filter(f => f === '')?.length === 0;
  };

  const onNext = async () => {
    try {
      const req = {
        ...userExtraData,
        oneSentence: oneSentenceInsights,
        completeTheSentence: completeTheSentenceInsights,
        story: personalStoryInsights,
        lastPage: userExtraData.completed ? 'Review' : 'ShareMedia',
      };
      setUserExtraData(req);
      console.log('save ', req);
      await api.setStoredDetails(userExtraData?.id, req);
      navigation.navigate('ShareMedia');
    } catch (error) {
      console.log('error : ', error);
    }
  };

  const placeholderText = `This is your space to share what you want about ${user?.first}. Could be why you chose ${user?.gender === 'Male' ? 'his' : 'her'} dominant trait, something most people wouldn't know, or something random, funny, cool, interesting, informational, exceptional, sentimental, surprising, epic….`;
  return (
    <View style={styles.container}>
      <NavHeader
        backButtonNavigation="DominantTrait"
        props={navigation}
        steps={[1, 1, 1, 1, 1, 1, 0]}
        width={globalStyles.container.width}
        height="112px"
      ></NavHeader>
      <ScrollView style={{ paddingHorizontal: 20, width: '100%' }}>
        <View style={{ width: '100%' }}>
          <Text style={styles.title}>Share your insights regarding {user?.first}.</Text>
          <View style={{ flexDirection: 'row' }}>
            <Text style={styles.subTitle}>
              Help {user?.first}'s matches know more about {user.gender === "Female" ? "her" : "him"} as a person.
            </Text>
          </View>
        </View>
        <View style={styles.separator} lightColor="#eee" darkColor="rgba(255,255,255,0.1)" />
        <View style={styles.userInfoEntry}>
          <Text style={styles.fieldTopLabel}>Describe {user?.first} in one sentence</Text>
          <ExpandingTextInput
            placeholderTextColor="#ADB5BD"
            value={oneSentenceInsights}
            placeholder="Enter Text Here"
            style={styles.userInputField}
            onChangeText={text => setOnceSentenceInsights(text)}
          ></ExpandingTextInput>
        </View>
        <View style={styles.userInfoEntry}>
          <Text style={styles.fieldTopLabel}>
            Complete the Sentence: I can count on {user?.first} to...
          </Text>
          <ExpandingTextInput
            placeholderTextColor="#ADB5BD"
            value={completeTheSentenceInsights}
            placeholder="Enter Text Here"
            style={styles.userInputField}
            onChangeText={text => setCompleteTheSentenceInsights(text)}
          ></ExpandingTextInput>
        </View>
        <View style={styles.userInfoEntry}>
          <Text style={styles.fieldTopLabel}>Personal Story</Text>
          <TextInput
            style={styles.userPersonalStory}
            value={personalStoryInsights}
            placeholderTextColor="#ADB5BD"
            placeholder={placeholderText}
            multiline={true}
            numberOfLines={5}
            onChangeText={text => setPersonalStoryInsights(text)}
          ></TextInput>
        </View>
        {globalStyles.container.height < 800 && (
          <View
            style={{
              width: '100%',
              paddingTop: 40,
              paddingBottom: 10,
            }}
          >
            <ButtonStandard
              disabled={
                !readyMoveNext([
                  oneSentenceInsights,
                  completeTheSentenceInsights,
                  personalStoryInsights,
                ])
              }
              label="Next"
              onPress={onNext}
            ></ButtonStandard>
          </View>
        )}
      </ScrollView>
      {globalStyles.container.height >= 800 && (
        <View
          style={{
            width: '100%',
            paddingVertical: 20,
            paddingHorizontal: 20,
          }}
        >
          <ButtonStandard
            disabled={
              !readyMoveNext([
                oneSentenceInsights,
                completeTheSentenceInsights,
                personalStoryInsights,
              ])
            }
            label="Next"
            onPress={onNext}
          ></ButtonStandard>
        </View>
      )}
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    backgroundColor: '#FFFFFF',
    width: `${globalStyles.container.width}px`,
    margin: 'auto',
  },
  title: {
    fontFamily: 'Ubuntu',
    fontWeight: '500',
    fontSize: globalStyles.container.height < 800 ? 22 : 26,
    color: '#212529',
    marginBottom: 8,
  },
  subTitle: {
    fontFamily: 'Jost Regular',
    fontSize: globalStyles.container.height < 800 ? 16 : 18,
    color: '#6C7570',
  },
  separator: {
    marginVertical: 10,
    marginBottom: 0,
    margin: 'auto',
    height: 1,
    backgroundColor: '#DEE2E6',
    width: '100%',
  },
  userInfoEntry: {
    width: '100%',
    marginTop: 20,
  },
  userPersonalStory: {
    width: '100%',
    height: 208,
    paddingTop: 9,
    paddingLeft: 10,
    paddingRight: 10,
    marginTop: 0,

    fontFamily: 'Jost Regular',
    fontSize: 18,
    color: '#212529',

    borderColor: '#ADB5BD',
    borderRadius: 8,
    borderWidth: 2,
    // paddingLeft: 10,
  },
  fieldTopLabel: {
    color: '#212529',
    fontFamily: 'Jost Regular',
    fontSize: 16,
    fontWeight: '600',
    marginBottom: 6,
  },
  userInputField: {
    fontFamily: 'Jost Regular',
    fontSize: 18,
    color: '#212529',
    width: 'calc(100% - 25px)',
    height: 50,
    borderColor: '#ADB5BD',
    borderRadius: 8,
    borderWidth: 2,
    paddingLeft: 10,
  },
});
