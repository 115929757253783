import { environment } from '../environments/environment';
import { UserDto, PhotoProps, SphereOfTrust } from './models.dto';

export const USER_ID_KEY = 'sot_user_id';

function bytesToMB(bytes: number) {
  const megabytes = bytes / (1024 * 1024);
  return megabytes.toFixed(2) + ' MB';
}

function extractValuesFromString(str: string) {
  const regex = /Got (\d+).*Maximum is (\d+)/;
  const match = regex.exec(str);

  if (match) {
    const valueGot = parseInt(match[1], 10);
    const valueMax = parseInt(match[2], 10);
    return { got: valueGot, max: valueMax };
  } else {
    return null;
  }
}

export class DSAPI {
  rootUri: string;
  baseUri: string;

  constructor() {
    this.rootUri = `${environment.serverUrl}/`;
    this.baseUri = `${this.rootUri}${environment.serverBasePath}`;
  }

  async _get(path: string) {    
    const response = await fetch(this.baseUri + path, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    });    
    if (response.status === 404) {
      return null;
    }
    const json = await response.json();
    return json;
  }

  async _post(path: string, data: any) {
    const response = await fetch(this.baseUri + path, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    });
    if (response.status === 404) {
      return null;
    }
    const json = await response.json();
    return json;
  }

  async getUserId() {
    const userId = window.localStorage.getItem(USER_ID_KEY);
    return userId;
  }

  async uploadUserPhoto(id: string, photo: any) {
    let signature;
    try {
      signature = await this._get(`/photo/signature?id=${id}`);
    } catch (e) {
      console.error('Error getting signature:', e);
    }
    // console.log('signature', signature);

    let res;
    try {
      const cloudName = environment.cloudinary.cloudName;
      const cloudinaryUrl = `https://api.cloudinary.com/v1_1/${cloudName}/image/upload`;

      const data = new FormData();
      // data.append('file', JSON.stringify({
      //   name: 'photox.jpg', //photo.fileName,
      //   type: photo.type,
      //   uri: photo.uri, // Platform.OS === 'ios' ? photo.uri.replace('file://', '') : photo.uri,
      // }));
      data.append('file', `data:image/jpeg;base64,${photo.base64}`);
      data.append('upload_preset', signature.preset);
      data.append('cloud_name', cloudName);
      data.append('signature', signature.signature);
      data.append('timestamp', signature.timestamp);
      data.append('api_key', signature.apiKey);

      res = await fetch(cloudinaryUrl, {
        method: 'post',
        body: data,
      });
      // console.log('res.status', res.status);
    } catch (e) {
      console.error('Error sending file to Cloudinary:', e);
      return null;
    }
    try {
      const json = await res.json();
      // console.log('json', json);
      // setPhoto(data.secure_url)
      if (json?.error?.message) {
        const fileSizeErrorParts = extractValuesFromString(json?.error?.message);
        if (fileSizeErrorParts?.got) {
          alert(
            `The maximum image size allowed is 25 MB.`
          );
        }
      }
      const ret: PhotoProps = {
        uri: json.secure_url,
        thumbnailUri: json.secure_url.replace('upload/v', 'upload/c_thumb,w_64,g_face/v'),
      };
      return ret;
    } catch (e) {
      console.error('Error parsing response from Cloudinary:', e);
      return null;
    }
  }

  async getUser(userId: string) {
    const json: UserDto = await this._get(`/sot/user/${userId}`);
    return json;
  }

  async submitSoT(userId: string, req: SphereOfTrust) {
    const json: any = await this._post(`/sot/user/${userId}`, req);
    return json;
  }

  async getStorageDetails(userId: string, alternateUserId: string) {
    const json: UserDto = await this._get(`/sot/user/${userId}/alt/${alternateUserId}`);
    
    return json;
  }

  async setStoredDetails(userId: string, reqBody: {}) {
    const json: any = await this._post(`/sot/${userId}`, reqBody);

    return json;
  }
}
